import './Footer.css'

function Footer() {
    var annee = new Date().getFullYear()
    return(
        <div className='Footer'>
            <p>Copyright © {annee} Marousé. All Rights Reserved</p>
        </div>
    )
}


export default Footer;