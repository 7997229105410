import './SubHeader.css'

function SubHeader() {
    return(
        <div className="SubHeader">
            <h5 className="SousTitre">COMMUNICATION | CONSCIENCE | SOPHROLOGIE</h5>
        </div>
    )
}

export default SubHeader;