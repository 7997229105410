import './Info2.css';
import Image2 from '../images/Image2.jpg'

function Info2() {
    return(
        
        <div className="Info2">
            <img src={Image2} className="Image2" alt='Image2'/>
            <div className="Paragraphe">
                <p>Et plus personnellement :</p>
                <p className="Text">Je suis moi-même maman de 3 enfants dont des jumeaux et la Communication Non Violente et la Sophrologie sont des atouts majeurs dans notre "aventure familiale".</p>
                <p className="Text">En effet, nous avons fait le choix de changer de mode éducatif : ce qui nous importe le plus aujourd'hui c'est la relation durable que nous pouvons construire avec nos enfants.</p>
                <p className="Text">Nous souhaitons que nos enfants puissent agir, non pas par crainte d'une punition ou souhait d'une récompense mais parce qu'ils ont compris ce que cette action leur apporte ou à quelle valeur elle répond.</p>
                <p className="Text">Je ne prétends pas que tout fonctionne toujours car c'est un long chemin de revoir notre propre mode éducatif et donc notre fonctionnement. C'est aussi, selon moi, un très beau parcours pour voir éclore des relations authentiques, coopératives, empathiques...</p>
            </div>
        </div>

    )
}

export default Info2;