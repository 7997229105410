import './Tarifs.css'
import Image4 from '../images/Image4.jpg'

function Tarifs() {
  return (
    <div className='TarifsAll'>
      <div className="Tarifs" id='tarifs'>
        <h3 className='TitreT'>Les Tarifs</h3>

        <div className='S'>
          <div className='SC'>
            <h5 className='T'>Séances de sophrologie collectives (3 personnes maximum) :</h5>
            <h5>10 séances : 170€</h5>
            <h5>À réaiser dans les 6 mois</h5>
          </div>

          <div className='SI'>
            <h5 className='T'>Séance de sophrologi individuelle :</h5>
            <h5>1 Séance : 50€</h5>
            <h5>10 Séances : 450€</h5>
          </div>
        </div>

        <div className='CNV'>
          <div className='CNVC'>
            <h5 className='T'>Groupe de Communication Non Violente (8 personnes maximum) :</h5>
            <h5>10 Séances : 120€</h5>
          </div>

          <div className='CNVI'>
            <h5 className='T'>Séance individuelle de Communication Non Violente :</h5>
            <h5>1 Séance : 50€</h5>
            <h5>10 Séances : 450€</h5>
          </div>
        </div>
      </div>

      <div className='Reglement'>

        <h3 className='TitreR'>Règlement</h3>
        
        <img className='img4' src={Image4} alt='Image4'/>

        <div >
          
          <li className='Rule1'>En cas d'annulation, prévenir au moins 24h à l'avance, toutes les séances non réalisées sans prévenir sont dues.</li>
          <li>Les règlements se font par chèques ou espèces.</li>
          <li>Si confinement, les cours seront maintenus en ligne en gardant le même planning, il n'y aurra pas de remboursement.</li>
          <li>L'horaire de rendez-vous est celui du début de séance.</li>
          <li>Une grande serviette et un plaid sont obligatoires.</li>
          <li>Les séances de sophrologie se pratiquent dans une tenue confortable.</li>
        </div>

      </div>
    </div>
  );
}

export default Tarifs;