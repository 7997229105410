import './Contact.css';

function Contact() {
    return (
        <div id='contact'>
            <h3 className='TitreC'>Contactez-Moi</h3>
            <div className='DivC'>

                <form action="https://formsubmit.co/freezingkas@gmail.com" method="POST" className="Contact">
                    <input type="hidden" name="_subject" value="Nouveau message" />
                    <input type="hidden" name="_template" value="box"/>
                    <input type="hidden" name="_captcha" value="false"/>
                    <input type="hidden" name="_next" value="https://marouse.com"/>
                    <div className="Name">
                        <label className="NameLabel" htmlFor='name'>Name:</label>
                    </div>
                    <div>
                        <input className="NameInput" name="Nom" type='text' id="name" required />
                    </div>
                    <div className="Email">
                        <label className="EmailLabel" htmlFor='email'>Email:</label>
                    </div>
                    <div>
                        <input className="EmailInput" name="E-mail" type='email' id="email" required />
                    </div>
                    <div className="Message">
                        <label className="MessageLabel" htmlFor='message'>Message:</label>
                    </div>
                    <div>
                        <textarea className="MessageText" name="Message" id="message" required />
                    </div>
                    <div className="Boutton">
                        <button type='submit'>Envoyer</button>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default Contact;