import './App.css';
import Header from './components/Header1.js'
import SubHeader from './components/SubHeader.js'
import Info1 from './components/Info1'
import Info2 from './components/Info2'
import Menu from './components/Menu.js'
import Contact from './components/Contact.js'
import Tarifs from './components/Tarifs.js'
import Footer from './components/Footer.js'

function App() {
  return (
    <div className="App">
      <Header />
      <Menu />
      <SubHeader />
      <Info1 />
      <Info2 />
      <Tarifs />
      <Contact />
      <Footer />
      
    </div>
  );
}

export default App;
