import './Menu.css'

function Menu() {
    return(
        <div className='Menu'>
            <p className='LigneMenu'>
                <a href='#presentation'>Présentation</a>|
                <a href='#tarifs'>Tarifs</a>|
                <a href='#contact'>Contact</a>
            </p>
        </div>
    )
}

export default Menu;