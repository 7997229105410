import Image1 from '../images/Image1.jpg'
import './Info1.css'

function Info1() {

    return(

        <div className="Info1">
            <img src={Image1} className="Image1" alt='Image1' />
            <p className="Text0" id='presentation'>
                Après avoir longtemps cherché à titre personnel comment dépasser mes blocages et apaiser les tensions relationnelles , j'ai découvert la Communication Non Violente (CNV) et la sophrologie. Convaincue de la puissance de ces deux disciplines complémentaires, j'ai décidé d'en faire mon activité pour les faire découvrir.</p>
​                <p className="Text1">
                Formée à la sophrologie par l'institut Cassiopé,  j'ai obtenu un titre RNCP de niveau III comme sophrologue praticien. Je suis attachée à une approche globale de la personne ce qui m'a conduit à me former également dans différents domaines :</p>
                <p className="Text1">
                Fleurs de Bach, Aromathérapie, Psychogénéalogie, Accompagnement du deuil...</p>
                <p className="Text1">
                Je me suis également formée au sein de l'ACNV à la Communication Non Violente avec Michèle Guez, Béatrix Piedtenu, Thomas d'Ansambourg, Véronique Gaspard...</p>
                <p className="Text1">
                Ces deux disciplines permettent en effet de mettre plus de conscience dans nos vies, de retrouver nos besoins et donc notre élan de vie. Avec la clarté de ce que nous souhaitons vivre, nous pouvons alors aligner chacun de nos pas pour en prendre le chemin.</p>
                <p className="Text1">
                Attachée au monde de l'enfant : j'ai exercé comme professeur des écoles et directrice d'école et je suis formée comme animatrice SEVE (Savoir Etre et Vivre Ensemble) pour méditer et philosopher dans les classes. Encore une fois, pour l'enfant aussi il me semble important de mettre du sens sur ce qu'il vit à l'école ou dans sa famille pour l'accompagner dans son épanouissement.. Un regard positif et bienveillant l'aide à se construire.</p>
        </div>
    )
}

export default Info1;