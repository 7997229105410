import logo from '../images/logo1.png';
import './Header1.css';

function Header() {

    return(
        <div className="Header">

            <img src={logo} height="50" alt="logo" className="Logo"/>
            
            <h3>SANDRINE<br></br>MAROUSÉ</h3>

            <li className='Tel'>06 16 18 12 49</li>

        </div>
    )
}

export default Header;